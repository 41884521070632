import React, {useState, useEffect} from 'react';
import Aux from '../../hoc/Aux/Aux';
import ProjectPlaceholder from '../../components/UI/Placeholder/ProjectPlaceholder/ProjectPlaceholder';
import Gallery from '../../components/Gallery/Gallery';
import GalleryItem from '../../components/Gallery/GalleryItem/GalleryItem';
import ShareButton from '../../components/UI/SocialShareButton/SocialShareButton';
import classes from "./Project.module.scss";
import {
    Row,
    Col,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import axios from '../../axios-base';
import moment from 'moment';
import SMSVote from "./SMSVote";
import FingerprintVote from "./FingerprintVote";
import VotePlaceholder from './VotePlaceholder';
import {Helmet} from "react-helmet";
import {useParams} from 'react-router-dom';
import {sendVirtualPageView} from "../../shared/utility";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faInstagram,
    faFacebookF
} from "@fortawesome/free-brands-svg-icons";
import {
    faCircle,
    faCircleCheck,
    faShareAlt,
    faTag,
    faPlay
} from '@fortawesome/free-solid-svg-icons'
import CountUp from "react-countup";

function Project(props) {

    const [loadedPost, setLoadedPost] = useState(null);

    let {id} = useParams();
    useEffect(() => {
        if (id) {
            // @todo : implement Optional chaining for cleaner nested property comparison
            if (!loadedPost || (loadedPost && loadedPost.id !== id)) {
                axios.get(`projects/${id}`)
                    .then(({data}) => setLoadedPost(data))
                    .catch(error => {
                        if (error.response) {
                            // The request was made and the server responded with a status code
                            // that falls out of the range of 2xx
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            // The request was made but no response was received
                            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                            // http.ClientRequest in node.js
                            console.log(error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', error.message);
                        }
                        console.log(error.config);
                    });

            }
            sendVirtualPageView(`/${id}`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let projectVoteCount = <span className="ps-1">0 Stimmen</span>;
    let voteComponent;

    if (loadedPost && props.votingPhases) {
        const startDate = props.votingPhases.voteStart;
        if (!props.votingPhases.voteStarted) {
            // placeholder - prior to voting
            let text = 'Am ' + moment(startDate).format('DD.MM.') + ' können Sie hier abstimmen!';
            voteComponent = <VotePlaceholder text={text}/>;
        } else if (props.votingPhases.voteEnded) {
            // placeholder - end of voting
            voteComponent = <VotePlaceholder text='Abstimmung ist beendet!'/>;
        } else {
            // voting in progress
            if (props.votingPhases.type.length > 1) {

                // if finale started
                if (!props.votingPhases.voteStartedFinale) {
                    voteComponent = <FingerprintVote {...props}/>;
                } else {
                    // the finale has started
                    if (loadedPost.isFinalist) {
                        voteComponent = <SMSVote projectCategory={loadedPost.projectCategory} {...props} />;
                    } else {
                        voteComponent = <VotePlaceholder text='Leider nicht im Finale'/>;
                    }
                }
            } else {
                if (props.votingPhases.type[0].phaseType === 'fingerprint') {
                    console.log("Fingerprint is loaded, ", props.votingPhases.type[0])
                    // Global Fingerprint contest
                    voteComponent = <FingerprintVote {...props}/>;
                } else {
                    //Global SMS contest
                    voteComponent = <SMSVote projectCategory={loadedPost.projectCategory} {...props}/>;
                }
            }
        }

        if (props.votingPhases.type && props.votingPhases.type.length > 1) {
            if (props.votingPhases.voteStartedFinale) {
                if (loadedPost.isFinalist) {
                    projectVoteCount = <span className="ps-1">Stimmenveröffentlichung nach Finalende</span>;
                }
            } else {
                if (loadedPost.voteCount) {
                    //console.log('vote count geladen...');
                    projectVoteCount = <CountUp className="ps-1" end={loadedPost.voteCount} decimal="," separator="." suffix=" Stimmen"/>;
                }
            }
        } else {
            projectVoteCount = <span className="ps-1">Stimmenveröffentlichung nach Finalende</span>;
        }
    }

    let project = (
        <Row className="py-3">
            <Col xs="12">
                <ProjectPlaceholder/>
            </Col>
        </Row>
    );

    if (loadedPost) {

        // move socialMediaServices configuration to database so services can eventually be setup per campaign via Cockpit
        const socialMediaServices = ['facebook', 'facebookMessenger', 'whatsApp', 'telegram', 'email', 'twitter', 'linkedin', 'xing']
        project = (
            <Aux>
                <Helmet>
                    <title>{`${process.env.REACT_APP_PROJECT_TITLE}: ${loadedPost.contestantName}`}</title>
                </Helmet>
                <Row className="my-3">
                    <Col xs="12" lg="5">
                        <Row className="mb-3">
                            <Col className="text-start">
                                <h3 className="mb-0">{loadedPost.contestantName}</h3>
                                <p className="my-0">{loadedPost.projectTitle}</p>
                                <p className="mb-0 text-primary">
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        fixedWidth
                                        className='fa-bounce' style={{ '--fa-animation-iteration-count': 1, '--fa-animation-delay': '1.9s' }}/>
                                    {projectVoteCount}
                                </p>
{/*
                                {loadedPost.projectCategory ?
                                < p className="mb-0 text-primary">
                                    <FontAwesomeIcon
                                    icon={faTag}
                                    fixedWidth
                                    className='pe-1 fa-bounce' style={{'--fa-animation-iteration-count': 1, '--fa-animation-delay': '2.0s'}}/>
                                    {loadedPost.projectCategory}
                                    </p>
                                : null}
*/}
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            {voteComponent}
                        </Row>
                        <Row className="mb-3">
                            <Col xs="12" lg="12">
                                <UncontrolledDropdown direction="down" className="d-grid">
                                    <DropdownToggle color="primary" outline>
                                        Mehr Stimmen sammeln? Jetzt teilen!
                                        <FontAwesomeIcon className="ps-1" icon={faShareAlt}/>
                                    </DropdownToggle>
                                    <DropdownMenu end className="mt-1 p-0" style={{boxShadow: "0 3px 4px 0px rgb(0 0 0 / 25%)"}}>
                                        <Row>
                                        {socialMediaServices.map((service, index) => {
                                            return (
                                                <Col xs="6" key={index} className={`${classes.DropdownItemContainer}`}>
                                                    <DropdownItem
                                                        className="btn-light btn"
                                                        toggle={false}>
                                                        <ShareButton shareType={service} shareTitle={loadedPost.contestantName}/>
                                                    </DropdownItem>
                                                    <DropdownItem divider className="m-0"/>
                                                </Col>
                                            )
                                        })}</Row>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Col>
                        </Row>

                    </Col>

                    <Col xs="12" lg="7">
                        <Row>
                            <Col xs={{size: 12, order: 2}} lg={{order: 1}}>
                                <h3 className="text-primary">Unsere Königin sollte WP-Königin werden, weil ...</h3>
                                <p>{loadedPost.contestantAnswer1}</p>
                            </Col>

                            <Col xs={{size: 12, order: 3}} lg={{order: 2}}>
                                <h3 className="text-primary">An welche Festmomente wir gerne zurückdenken:</h3>
                                <p>{loadedPost.contestantAnswer2}</p>
                            </Col>

                            <Col xs={{size: 12, order: 1}} lg={{order: 3}} className="px-0 p-md-3">
                                <Gallery>
                                    {loadedPost.projectGallery.map((galleryItem, index) => (
                                        <GalleryItem
                                            key={index}
                                            src={galleryItem}
                                            alt={'Galeriebild ' + index}/>
                                    ))}
                                </Gallery>
                            </Col>
                            <Col xs={{size: 12, order: 4}} lg={{order: 4}}>
                                {loadedPost.contestantInstagramProfile || loadedPost.contestantFacebookProfile || loadedPost.contestantYouTubeChannel ?
                                    <h3 className="text-primary">Jetzt dieses Profil auch auf Social Media erkunden:</h3>
                                    : null}
                                {loadedPost.contestantInstagramProfile ?
                                    <a className="fa-3x pe-3" href={loadedPost.contestantInstagramProfile} target="_blank" rel="noopener nofollow noreferrer">
                                        <span className={`${classes.SocialMediaIcon} ${classes.Instagram} fa-layers`}>
                                            <FontAwesomeIcon className={classes.gradient} icon={faCircle} mask={faCircle} inverse />
                                            <FontAwesomeIcon icon={faInstagram} inverse transform="shrink-6" />
                                        </span>
                                    </a>
                                : null}
                                {loadedPost.contestantFacebookProfile ?
                                    <a className="fa-3x pe-3" href={loadedPost.contestantFacebookProfile} target="_blank" rel="noopener nofollow noreferrer">
                                        <span className={`${classes.SocialMediaIcon} ${classes.Facebook} fa-layers`}>
                                            <FontAwesomeIcon icon={faCircle}/>
                                            <FontAwesomeIcon className="fa-inverse" transform="shrink-6" icon={faFacebookF} />
                                        </span>
                                    </a>
                                : null}
                                {loadedPost.contestantYouTubeChannel ?
                                    <a className="fa-3x pe-3" href={loadedPost.contestantYouTubeChannel} target="_blank" rel="noopener nofollow noreferrer">
                                        <span className={`${classes.SocialMediaIcon} ${classes.YouTube} fa-layers`}>
                                            <FontAwesomeIcon icon={faCircle}/>
                                            <FontAwesomeIcon className="fa-inverse" transform="shrink-8" icon={faPlay} />
                                        </span>
                                    </a>
                                : null}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Aux>
        )
    }

    return (
        <Aux>
            {project}
        </Aux>
    );
}

export default Project;
